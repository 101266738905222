.nav {
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 0 20px 0 0;
}

.nav__link {
    margin: auto 0;
    color: #FAFAFF;
    text-align: start;
    font-size: 24px;
    line-height: 26px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
}

.content {
    margin: 0 auto;
    background-color: #FAFAFF;
}

.content__main_house_semidvorie {
    width: 100%;
    min-height: 100vh;
    background: url(../image/Semidvorie/House1.png) no-repeat;
    background-size: cover;
}

@media screen and (max-width: 1024px) {
    .content__main_house_semidvorie {
        min-height: 50vh;
    }
}

@media screen and (max-width: 750px) {
    .content__main_house_semidvorie {
        min-height: 25vh;
    }
}

.content__main_house_shablykino {
    width: 100%;
    min-height: 100vh;
    background: url(../image/Shablykino/House1/Shablykino.jpeg) no-repeat;
    background-size: cover;
}

@media screen and (max-width: 750px) {
    .content__main_house_shablykino {
        min-height: 30vh;
    }
}

.content__name {
    width: min-content;
    margin: 0 52px 0 auto;
    padding: 52px 0 0;
    color: #fff;
    text-align: right;
    font-size: 96px;
    line-height: 98px;
    font-weight: 600;
    text-transform: uppercase;
}

@media screen and (max-width: 750px) {
    .content__name {
        margin-right: 5%;
        padding-top: 5%;
        font-size: 56px;
        line-height: 62px;
    }
}

@media screen and (max-width: 530px) {
    .content__name {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 370px) {
    .content__name {
        font-size: 36px;
        line-height: 41px;
    }
}

.content__title {
    width: fit-content;
    padding: 0;
    margin: 0 0 20px;
    color: #021C08;
    text-align: start;
    font-size: 40px;
    line-height: 58px;
    font-weight: 400;
    text-transform: uppercase;
}

@media screen and (max-width: 650px) {
    .content__title {
        font-size: 22px;
        line-height: 36px;
    }
}

@media screen and (max-width: 430px) {
    .content__title {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 5px;
    }
}

.about {
    padding: 30px 0;
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}

@media screen and (max-width: 900px) {
    .about {
        flex-direction: column;
    }
}

@media screen and (max-width: 430px) {
    .about {
        padding: 15px 0;
    }
}

.about__text {
    width: 90%;
    margin: 0 auto;
    color: #021C08;
    font-size: 30px;
    line-height: 36px;
    font-weight: 200;
    text-align: center;
    min-height: 216px;
}

@media screen and (max-width: 1024px) {
    .about__text {
        width: 100%;
        min-height: 200px;
        font-size: 30px;
        line-height: 34px;
    }
}

@media screen and (max-width: 900px) {
    .about__text {
        font-size: 24px;
        line-height: 28px;
    }
}

@media screen and (max-width: 650px) {
    .about__text {
        font-size: 22px;
        line-height: 28px;
    }
}

@media screen and (max-width: 550px) {
    .about__text {
        min-height: 100px;
        font-size: 14px;
        line-height: 18px;
    }
}

.characteristics {
    margin: 0;
    padding: 0;
    margin: 0 auto;
    width: 90%;
}

.characteristic {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.characteristic__cards {
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.photo__card_size_big {
    max-width: 100%;
    max-height: 707px;
    object-fit: contain;
}

.photo__cards {
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
    padding: 30px 0 0;
}

@media screen and (max-width: 550px) {
    .photo__cards {
        padding-top: 10px;
    }
}

.photo__cards_type_shablykino {
    padding: 0;
    grid-template-columns: repeat(8, 1fr);
}

.photo__card_size_small {
    width: 100%;
    height: 115px;
    object-fit: cover;
    border-radius: 10px;
}

@media screen and (max-width: 550px) {
    .photo__card_size_small {
        height: 40px;
    }
}

.characteristic__texts {
    width: 100%;
    margin: 30px 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr 1fr);
}


@media screen and (max-width: 450px) {
    .characteristic__texts {
        grid-template-columns: repeat(2, 1fr);
        margin: 20px 0 10px;
    }
}

.characteristic__text {
    margin: 0;
    padding: 0 5px;
    color: #021C08;
    text-align: end;
    font-size: 30px;
    line-height: 34px;
    font-weight: 600;
    min-height: 52px;
    border-bottom: 2px solid rgba(2, 28, 8);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.characteristic__text:nth-child(2n+2) {
    border-right: 2px solid rgba(2, 28, 8);
}

.characteristic__text:nth-child(4n) {
    border-right: none;
}

.characteristic__text:nth-child(-n+4) {
    border-top: 2px solid rgba(2, 28, 8);
}

@media screen and (max-width: 1024px) {
    .characteristic__text {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        min-height: 42px;
    }
}

@media screen and (max-width: 820px) {
    .characteristic__text {
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
    }
}

@media screen and (max-width: 550px) {
    .characteristic__text {
        border-bottom: 1px solid rgba(2, 28, 8);
        font-size: 14px;
        line-height: 20px;
    }

    .characteristic__text:nth-child(2n+2) {
        border-right: 1px solid rgba(2, 28, 8);
    }

    .characteristic__text:nth-child(4n) {
        border-right: none;
    }

    .characteristic__text:nth-child(-n+4) {
        border-top: 1px solid rgba(2, 28, 8);
    }
}

@media screen and (max-width: 450px) {
    .characteristic__text {
        font-size: 12px;
        line-height: 16px;
        border-bottom: 1px solid rgba(2, 28, 8);
    }

    .characteristic__text:nth-child(2n+2) {
        border-right: none;
    }

    .characteristic__text:nth-child(2n+1) {
        border-right: 1px solid rgba(2, 28, 8);
    }

    .characteristic__text:nth-child(-n+4) {
        border-top: none;
    }

    .characteristic__text:nth-child(23) {
        border-bottom: none;
    }

    .characteristic__text:nth-child(24) {
        border-bottom: none;
    }
}

.feature {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
}

.feature__title {
    margin: 0 0 30px;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 33px;
    color: #021C08;
}

@media screen and (max-width: 430px) {
    .feature__title {
        margin-bottom: 5px;
        font-size: 22px;
        line-height: 36px;
    }
}

.feature__texts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
}

.feature__text {
    margin: 0 auto;
    padding: 2%;
    display: flex;
    width: 100%;
    height: 15vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: 200;
    font-size: 22px;
    line-height: 26px;
    color: #021C08;
    box-sizing: border-box;
    border: 2px solid rgba(2, 28, 8);
    border-radius: 10px;
}

.feature__text__type_price {
    margin-top: 20px;
    font-weight: 600;
    font-size: 40px;
    line-height: 110%;
}

@media screen and (max-width: 650px) {
    .feature__text {
        height: 25vh;
    }
}

@media screen and (max-width: 520px) {
    .feature__text {
        height: 20vh;
        font-size: 16px;
        line-height: 20px;
    }
}

.options {
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .options {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.option {
    width: 30%;
}

@media screen and (max-width: 1000px) {
    .option {
        width: 100%;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 800px) {
    .option {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 430px) {
    .option {
        width: 100%;
        margin-bottom: 2px;
    }
}

.option__description {
    width: fit-content;
    min-height: 80px;
    padding: 10px 0 10px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #021C08;
    text-transform: uppercase;
}

@media screen and (max-width: 1388px) {
    .option__description {
        font-size: 20px;
        line-height: 24px;
    }
}

@media screen and (max-width: 1270px) {
    .option__description {
        font-size: 18px;
        line-height: 22px;
    }
}

@media screen and (max-width: 1160px) {
    .option__description {
        min-height: 60px;
        font-size: 16px;
        line-height: 20px;
    }
}

@media screen and (max-width: 1045px) {
    .option__description {
        min-height: 80px;
        font-size: 16px;
        line-height: 20px;
    }
}

@media screen and (max-width: 1000px) {
    .option__description {
        font-size: 46px;
        line-height: 50px;
    }
}

@media screen and (max-width: 900px) {
    .option__description {
        font-size: 34px;
        line-height: 38px;
    }
}

@media screen and (max-width: 650px) {
    .option__description {
        font-size: 28px;
        line-height: 32px;
    }
}

@media screen and (max-width: 540px) {
    .option__description {
        font-size: 22px;
        line-height: 26px;
    }
}

@media screen and (max-width: 430px) {
    .option__description {
        font-size: 18px;
        line-height: 22px;
        min-height: 40px;
    }
}

@media screen and (max-width: 360px) {
    .option__description {
        font-size: 16px;
        line-height: 20px;
    }
}

.option__photo {
    max-width: 100%;
    max-height: 707px;
    object-fit: contain;
    border-radius: 15px;
}

.payment {
    display: flex;
    flex-direction: row;
    max-width: 90%;
    justify-content: space-between;
    align-items: start;
    margin: 30px auto 15px;
}

@media screen and (max-width: 725px) {
    .payment {
        max-width: 90%;
    }
}

@media screen and (max-width: 430px) {
    .payment {
        display: block;
        position: relative;
    }
}

.payment__logo {
    max-width: 74px;
}

@media screen and (max-width: 800px) {
    .payment__logo {
        max-width: 90px;
    }
}

@media screen and (max-width: 725px) {
    .payment__logo {
        max-width: 105px;
    }
}

@media screen and (max-width: 430px) {
    .payment__logo {
        max-width: 74px;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.payment__information {
    margin: 0 0;
    padding: 0;
    max-width: 85%;

}

@media screen and (max-width: 430px) {
    .payment__information {
        max-width: 100%;
    }
}

.payment__description {
    margin: 0;
    padding: 0;
    width: fit-content;
    text-transform: uppercase;
    font-weight: 400;
    color: #021C08;
    font-size: 24px;
    line-height: 26px;
}

@media screen and (max-width: 430px) {
    .payment__description {
        font-size: 18px;
        line-height: 22px;
        text-transform: none;
        margin: 0 auto 0 0;
        width: 100%;
        text-align: left;
    }
}

.payment__list {
    margin: 0;
    padding: 5px 0 0 20px;

}

@media screen and (max-width: 430px) {
    .payment__list {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
        margin: 0 0 0 auto;
    }
}

.payment__element {
    margin: 0 0 0 20px;
    padding: 0;
    width: fit-content;
    font-weight: 400;
    color: #021C08;
    font-size: 20px;
    line-height: 22px;
}

@media screen and (max-width: 430px) {
    .payment__element {
        font-weight: 200;
        font-size: 16px;
        line-height: 20px;
    }
}

.destination {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 450px;
}

@media screen and (max-width: 900px) {
    .destination {
        width: 100%;
        min-height: 300px;
    }
}

@media screen and (max-width: 430px) {
    .destination {
        width: 100%;
        min-height: 255px;
    }
}

.destination__information {
    min-height: 212px;
    margin: 30px 0 0;
}

@media screen and (max-width: 1280px) {
    .destination__information {
        margin: 0;
    }
}

.destination__title {
    margin: 0;
    min-height: 26px;
    padding: 15px 0 7px;
    font-weight: 400;
    color: #021C08;
    font-size: 24px;
    line-height: 26px;
}

@media screen and (max-width: 430px) {
    .destination__title {
        min-height: 22px;
        padding: 5px 0 0;
        font-size: 18px;
        line-height: 22px;
    }
}

.destination__text {
    margin: 0;
    min-height: 44px;
    font-weight: 400;
    color: #021C08;
    font-size: 20px;
    line-height: 22px;
    text-align: justify;
}

@media screen and (max-width: 430px) {
    .destination__text {
        font-weight: 200;
        font-size: 16px;
        line-height: 20px;
    }
}

.destination__foto {
    width: 50%;
    min-height: 450px;
    border: none;
}

@media screen and (max-width: 900px) {
    .destination__foto {
        width: 100%;
        border-radius: 15px;
    }
}

@media screen and (max-width: 430px) {
    .destination__foto {
        min-height: 250px;
    }
}

.popup { 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility .2s, opacity .2s ease-in;
}

.popup_opened {
    display: flex;
    visibility: visible;
    opacity: 1;
    transition: 0.5s;
}

.popup__container { 
    position: relative; 
    margin: 0 auto;
    z-index: 2; 
    transition: 0.5s; 
}

.popup__container_type_register {
    min-height: 330px; 
    border-radius: 10px; 
    background-color: #fff; 
    display: flex; 
}

.popup__container_type_contact {
    padding: 20px 0;
    width: 70%; 
    min-height: 330px; 
    border-radius: 20px; 
    background-color: #fff; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 430px) {
    .popup__container_type_contact {
        border-radius: 10px;
    }
}

.popup__container_type_image {
    margin: 0 auto;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 430px) {
    .popup__container_type_image {
        width: 90%;
    }
}

.popup__figure {
    padding: 0;
    margin: 0 auto;
    width: 70%;
}

@media screen and (max-width: 430px) {
    .popup__figure {
        width: 90%;
    }
}

.popup__picture {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
    margin: 0 auto 10px;
    border-radius: 30px;
    object-fit: contain;
}

@media screen and (max-width: 1344px) {
    .popup__picture {
        border-radius: 15px;
    }
}

@media screen and (max-width: 430px) {
    .popup__picture {
        max-height: 300px;
        margin-bottom: 5px;
    }
}

.popup__content { 
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.popup__title {
    width: fit-content;
    margin: 0 5% 20px;
    color: #021C08;
    text-align: start;
    font-size: 56px;
    line-height: 58px;
    font-weight: 400;
}

@media screen and (max-width: 800px) {
    .popup__title {
        font-size: 36px;
        line-height: 38px;
    }
}

@media screen and (max-width: 650px) {
    .popup__title {
        font-size: 26px;
        line-height: 28px;
    }
}

.popup__title_type_image {
    width: fit-content;
    margin: 10px 0;
    color: #ffffff;
    text-align: start;
    font-size: 30px;
    line-height: 34px;
    font-weight: 200;
}

@media screen and (max-width: 430px) {
    .popup__title_type_image {
        font-size: 12px;
        line-height: 16px;
        margin: 2px 0;
    }
}

.photo__cards_type_shablykino {
    width: 70%;
    padding: 0;
    grid-template-columns: repeat(8, 1fr);
}

@media screen and (max-width: 430px) {
    .photo__cards_type_shablykino {
        gap: 3px;
        width: 90%;
    }
}

.popup__card_size_small {
    width: 100%;
    height: 70px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}

@media screen and (max-width: 550px) {
    .popup__card_size_small {
        height: 50px;
        border-radius: 5px;
    }
}

@media screen and (max-width: 430px) {
    .popup__card_size_small {
        height: 30px;
        border-radius: 5px;
    }
}

.contact__form {
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
    text-align: justify;
    padding: 17px 0 7px;
}

.contact__inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 228px;
}

@media screen and (max-width: 800px) {
    .contact__inputs {
        height: 198px;
    }
}

@media screen and (max-width: 430px) {
    .contact__inputs {
        height: 148px;
    }
}

.contact__input {
    margin: 20px 0;
    font-style: normal;
    font-weight: 200;
    font-size: 32px;
    line-height: 34px;
    color: #021C08;
    padding: 0 0 7px;
    border: 0;
    height: 36px;
    box-sizing: border-box;
    border-bottom: 1px solid #021C08;
    background-color: transparent;
}

@media screen and (max-width: 800px) {
    .contact__input {
        font-size: 22px;
        line-height: 24px;
        height: 26px;
        margin: 15px 0;
        padding-bottom: 2px;
    }
}

@media screen and (max-width: 650px) {
    .contact__input {
        font-size: 18px;
        line-height: 20px;
    }
}

@media screen and (max-width: 430px) {
    .contact__input {
        height: 18px;
        font-size: 16px;
        line-height: 18px;
        margin: 10px 0;
    }
}

.contact__input::placeholder {
    color: #CCCCCC;
}

.contact__input:active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
}

.contact__button {
    width: min-content;
    padding: 0 6%;
    height: 76px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    font-weight: 200;
    font-size: 32px;
    line-height: 32px;
    cursor: pointer;
    color: #021C08;
    border: 2px solid #021C08;
    border-radius: 60px;
    text-transform: lowercase;
    background-color: transparent;
}

@media screen and (max-width: 800px) {
    .contact__button {
        font-size: 22px;
        line-height: 22px;
        height: 50px;
    }
}

@media screen and (max-width: 650px) {
    .contact__button {
        font-size: 18px;
        line-height: 18px;
        height: 42px;
        padding: 0 5%;
    }
}

@media screen and (max-width: 430px) {
    .contact__button {
        height: 37px;
        font-size: 16px;
        line-height: 16px;
        border: 1px solid #021C08;
    }
}

.contact__text {
    margin: 0 auto;
    width: 90%;
    font-weight: 400;
    font-size: 34px;
    line-height: 37px;
    text-align: justify;
}

@media screen and (max-width: 800px) {
    .contact__text {
        font-size: 26px;
        line-height: 28px;
    }
}

@media screen and (max-width: 650px) {
    .contact__text {
        font-size: 20px;
        line-height: 22px;
    }
}

@media screen and (max-width: 430px) {
    .contact__text {
        font-size: 16px;
        line-height: 18px;
    }
}

.popup__image {
    margin: 0 auto;
    width: 120px;
    height: 120px;
    color: #021C08;
}

.popup__text {
    width: 82%;
    text-align: center;
    margin: 32px auto 0;
    color: #021C08;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
}

.popup__button-close {
    border: 0;
    background: url(../image/krest.svg) no-repeat center;
    position: absolute;
    width: 32px;
    height: 32px;
    right: 0px;
    top: -40px;
    cursor: pointer;
} 

.popup__button-close:hover {
    opacity: .6;
} 

@media screen and (max-width: 515px) {
    .popup__button-close {
        right: -5px;
        top: -40px;
        background-image: url(../image/minkrest.svg);
    }
}