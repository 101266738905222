.App {
  background-color: #051f08;
  padding: 0;
  margin: 0;
}

.header {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin: 0 auto;
}

.header__logo {
  width: 30%;
  margin: 0;
  cursor: pointer;
}

.header__button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #000;
  color: #FAFAFF;
  height: fit-content;
  text-transform: lowercase;
  padding: 8px 2%;
  width: fit-content;
  font-size: 28px;
  line-height: 28px;
  margin: 0;
  border-radius: 40px;
  cursor: pointer;
  text-transform: uppercase;
}

@media screen and (max-width: 1000px) {
  .header__button {
    font-size: 19px;
    line-height: 19px;
  }
}

@media screen and (max-width: 500px) {
  .header__button {
    font-size: 11px;
  }
}

.main {
  margin: 0 auto;
  padding: 45px 0 0;
  background-color: #FAFAFF;
}

.houses {
  margin: 0 auto;
  width: 90%;
  padding-bottom: 60px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}

@media screen and (max-width: 500px) {
  .houses {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }
}

.house {
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto;
  gap: 16px;
}

.house__foto {
  width: 100%;
  border-radius: 40px;
  box-sizing: border-box;
}

@media screen and (max-width: 950px) {
  .house__foto {
    border-radius: 15px;
  }
}

.house__buttom {
  box-sizing: border-box; 
  padding: 8px 2%;
  width: fit-content;
  height: fit-content;
  color: #051f08;
  font-size: 40px;
  line-height: 40px;
  border: 3px solid #051f08;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-transform: lowercase;
  background-color: transparent;
}

.house__buttom:hover {
  background-color: #133d39;
  color: #fff;
  border: 5px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
}

@media screen and (max-width: 950px) {
  .house__buttom {
    font-size: 24px;
    line-height: 36px;
    border: 2px solid #051f08;
  }
}

@media screen and (max-width: 730px) {
  .house__buttom {
    font-size: 27px;
    line-height: 150%;
    padding: 2px 20px;
    border: 2px solid #051f08;
  }
}

@media screen and (max-width: 500px) {
  .house__buttom {
    font-size: 20px;
    line-height: 20px;
  }
}

@media screen and (max-width: 440px) {
  .house__buttom {
    border: 1.5px solid #000;
    font-size: 16px;
    line-height: 22px;
    width: 120px;
    height: fit-content;
  }
}

.house__title {
  margin: 0;
  box-sizing: border-box; 
  color: #051f08;
  font-size: 36px;
  line-height: 60px;
  width: fit-content;
  border-bottom: 3px solid #051f08;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

@media screen and (max-width: 950px) {
  .house__title {
    font-size: 24px;
    line-height: 38px;
  }
}

@media screen and (max-width: 615px) {
  .house__title {
    font-size: 22px;
    line-height: 30px;
  }
}

@media screen and (max-width: 570px) {
  .house__title {
    font-size: 18px;
    line-height: 30px;
  }
}

@media screen and (max-width: 440px) {
  .house__title {
    font-size: 16px;
    line-height: 28px;
  }
}

@media screen and (max-width: 390px) {
  .house__title {
    border-bottom: 2px solid #FAFAFF;
    font-size: 13px;
    line-height: 18px;
  }
}

.house__text {
  color: #051f08;
  margin: 0;
  padding-top: 10px;
  box-sizing: border-box;
  font-size: 24px;
  line-height: 30px;
  width: fit-content;
  min-height: 80px;
}

@media screen and (max-width: 1070px) {
  .house__text {
    min-height: 90px;
  }
}

@media screen and (max-width: 950px) {
  .house__text {
    font-size: 22px;
    line-height: 26px;
    max-width: 379px;
  }
}

@media screen and (max-width: 670px) {
  .house__text {
    min-height: 110px;
  }
}

@media screen and (max-width: 500px) {
  .house__text {
    min-height: 60px;
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (max-width: 440px) {
  .house__text {
    font-size: 13px;
    line-height: 16px;
  }
}

@media screen and (max-width: 400px) {
  .house__text {
    font-size: 11px;
    line-height: 14px;
  }
}

.developer {
  color: #fff;
  margin: 0 auto;
  padding-bottom: 45px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .developer {
    color: #fff;
    border-image: linear-gradient(to right, #fff 30%, #b7c1be 30%);
    border-image-slice: 1;
    z-index: 1;
  }
}

.developer__title {
  color: #051f08;
  margin: 0 auto;
  padding: 0 0 20px 0;
  font-size: 64px;
  line-height: 70px;
  width: 90%;
  display: flex;
  justify-content: left;
  text-transform: uppercase;
}

@media screen and (max-width: 650px) {
  .developer__title {
    font-size: 44px;
    line-height: 58px;
  }
}

@media screen and (max-width: 500px) {
  .developer__title {
    padding: 20px 0 10px;
    font-size: 34px;
    line-height: 38px;
  }
}

@media screen and (max-width: 390px) {
  .developer__title {
    font-size: 24px;
    line-height: 28px;
  }
}

.developer__descriptions {
  color: #051f08;
  margin: 0 auto;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

@media screen and (max-width: 1110px) {
  .developer__descriptions {
    max-width: 80%;
  }
}

@media screen and (max-width: 1070px) {
  .developer__descriptions {
    max-width: 90%;
  }
}

.developer__texts {
  margin: 0;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 10px;
  color: #000;
}

@media screen and (max-width: 770px) {
  .developer__texts {
    margin: 0 auto;
    width: 90%;
  }
}

.developer__text {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  text-align: justify;
}

@media screen and (max-width: 950px) {
  .developer__text {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 770px) {
  .developer__text {
    text-align: left;
    font-size: 18px;
    line-height: 22px;
  }
}

.footer {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}

@media screen and (max-width: 490px) {
  .footer {
    padding: 10px 0;
  }
}

.footer__title {
  padding: 0 0 10px;
  margin: 0;
  text-decoration: none;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-transform: uppercase;
}

@media screen and (max-width: 490px) {
  .footer__title {
    padding-bottom: 5px;
    font-size: 12px;
    line-height: 16px;
  }
}

.footer__tel {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
}

@media screen and (max-width: 490px) {
  .footer__tel {
    font-size: 12px;
    line-height: 16px;
  }
}

.footer__link {
  text-decoration: none;
  color: #FAFAFF;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

@media screen and (max-width: 490px) {
  .footer__link {
    font-size: 12px;
    line-height: 16px;
  }
}
